import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import dailyModal from './modules/daily-modal'
import campaign from './modules/campaign'
import tiers from './modules/tiers'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        auth,
        dailyModal,
        campaign,
        tiers
    },
    getters
})
