<template>
    <div class="link-list-container">
        <div class="links-title">
            {{ linksTitle }}
        </div>
        <ul class="link-list">
            <li v-for="link in links" :key="link.label">
                <a
                    :href="link.link"
                    class="footer-link"
                    target="_blank"
                >
                    {{ link.label }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            linksTitle: {
                type: String,
                required: true
            },
            links: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>
.link-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.links-title {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
}

.link-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
}

.footer-link {
    text-decoration: none;
    color: #495057;
    font-size: 0.875rem;
}

@media screen and (min-width: 768px) {
    .link-list-container {
        width: unset;
    }
}
</style>
