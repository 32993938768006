<template>
    <div class="opap-navbar">
        <div class="container">
            <div
                ref="leftDropdownContainer"
                class="left-dropdown-container"
                @click="toggleSpringboard"
            >
                <springboard-svg
                    v-if="!isSpringboardOpen"
                    class="springboard ignoreClick"
                />
                <div
                    v-else
                    class="close"
                    :class="{open: isSpringboardOpen}"
                >
                    <span />
                    <span />
                </div>
            </div>
            <transition name="slide-right">
                <springboard
                    v-if="isSpringboardOpen"
                    ref="leftDropdown"
                    class="left-dropdown"
                />
            </transition>
            <div class="navbar-brand-container desktop-only">
                <a
                    class="logo-link"
                    href="https://casino.pamestoixima.gr"
                >
                    <img
                        :src="require('@/assets/images/nav-icons/main-logo.svg')"
                        alt=""
                    >
                </a>
            </div>
            <div class="navbar-brand-container mobile-only">
                <a
                    class="logo-link"
                    href="https://casino.pamestoixima.gr"
                >
                    <img
                        :src="require('@/assets/images/nav-icons/mobile-logo.png')"
                        alt=""
                    >
                </a>
            </div>
            <div class="pam-links desktop-only">
                <a
                    class="pam-link"
                    href="https://www.pamestoixima.gr/home"
                    target="_blank"
                >
                    {{ $t('navbar.stoixima') }}
                </a>
                <a
                    class="pam-link"
                    href="https://www.pamestoixima.gr/inplay"
                    target="_blank"
                >
                    {{ $t('navbar.liveStoixima') }}
                </a>
                <a
                    class="pam-link"
                    :href="virtualsLink"
                    target="_blank"
                >
                    {{ $t('navbar.virtuals') }}
                </a>
                <a
                    class="pam-link active"
                    :href="'https://casino.pamestoixima.gr/home'"
                    target="_blank"
                >
                    {{ $t('navbar.casino') }}
                </a>
                <a
                    class="pam-link"
                    :href="'https://casino.pamestoixima.gr/games'"
                    target="_blank"
                >
                    {{ $t('navbar.slots') }}
                </a>
                <a
                    class="pam-link"
                    :href="'https://casino.pamestoixima.gr/live-games'"
                    target="_blank"
                >
                    {{ $t('navbar.liveCasino') }}
                </a>
                <a
                    class="pam-link"
                    :href="'https://casino.pamestoixima.gr/new-generation-games'"
                    target="_blank"
                >
                    {{ $t('navbar.games') }}
                </a>
            </div>
            <div
                id="quickLogin"
                data-game-type="casino"
                data-widget-host="club"
                data-config-channel="WEB"
                :config-deeplinkurl="`${getOpapWidgetAccountDomain}/my-account/`"
            />
            <navbar-language-select />
        </div>
    </div>
</template>

<script>
    import SpringboardSvg from '@/components/svg/SpringboardSvg'
    import Springboard from '@/components/opap-navbar/Springboard'
    import NavbarLanguageSelect from '@/components/opap-navbar/NavbarLanguageSelect'

    export default {
        components: { SpringboardSvg, Springboard, NavbarLanguageSelect },
        computed: {
            isSpringboardOpen() {
                return this.$store.getters.getIsSpringboardOpen
            },
            isEnglishLang() {
                return this.$store.getters.getLanguage === 'en'
            },
            virtualsLink() {
                return this.isEnglishLang ? 'https://virtuals.pamestoixima.gr/en/virtuals' : 'https://virtuals.pamestoixima.gr/games/virtuals'
            },
            getOpapWidgetAccountDomain() {
                return process.env.VUE_APP_OPAP_WIDGET_ACCOUNT_URL
            }
        },
        created() {
            this.initClickEventListener()
        },
        beforeDestroy() {
            document.removeEventListener('click', this.bodyClickEvent)
        },
        methods: {
            toggleSpringboard() {
                if (this.isSpringboardOpen) {
                    this.$store.commit('setIsSpringboardOpen', false)
                } else {
                    this.$store.commit('setIsSpringboardOpen', true)
                }
            },
            initClickEventListener() {
                const bodyElement = document.body
                bodyElement.addEventListener('click', this.bodyClickEvent)
            },
            bodyClickEvent(e) {
                // SpringBoard menu
                if (
                    e.target !== this.$refs.leftDropdownContainer &&
                    !this.$refs.leftDropdown?.$el.contains(e.target) &&
                    !(e.target.classList.contains('ignoreClick') || e.target.classList.contains('svg')) &&
                    this.isSpringboardOpen
                ) {
                    this.$store.commit('setIsSpringboardOpen', false)
                }
            }
        }
    }
</script>

<style scoped>
.opap-navbar {
    height: var(--mobile-opap-nav-height);
    width: 100%;
    background: var(--ps-black);
}

.container {
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    position: relative;
}

.left-dropdown-container {
    width: 100%;
    max-width: 19px;
    height: 19px;
    display: grid;
    place-items: center;
    position: relative;
    cursor: pointer;
}

.left-dropdown-container::before {
    content: '';
    position: absolute;
    top: -17px;
    right: -1rem;
    width: 30vw;
    height: var(--mobile-opap-nav-height);
    background-color: var(--ps-red);
}

.navbar-brand-container {
    max-width: 100px;
    margin-right: auto;
    height: 100%;
    z-index: 1;
    position: relative;
}

.navbar-brand-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 52px;
    background-color: var(--ps-red);
    clip-path: polygon(0 0%, 45% 0, 6% 100%, 0% 100%);
    z-index: -1;
}

.logo-link {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    text-decoration: none;
}

.logo-link:focus {
    text-decoration: none;
    outline: none;
}

.logo-link img {
    width: 100%;
    height: auto;
}

.logo-link .logo-text {
    color: var(--ps-white);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0.1px;
}

.close {
    width: 18px;
    height: 18px;
    position: relative;
    cursor: pointer;
}

.close span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--ps-white);
    border-radius: 0.5rem;
    left: 0;
}

.close span,
.springboard {
    z-index: 1;
}

.close.open span:nth-child(1) {
    top: 6px;
    transform: rotate(135deg);
}

.close.open span:nth-child(2) {
    top: 6px;
    transform: rotate(-135deg);
}

.slide-right-enter-active,
.slide-left-enter-active,
.slide-right-leave-active,
.slide-left-leave-active {
    transition-property: transform, opacity;
    transition-duration: 0.15s, 0.25s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-right-enter,
.slide-right-leave-active {
    opacity: 0;
    transform: translateX(-100%);
}

.slide-left-enter,
.slide-left-leave-active {
    opacity: 0;
    transform: translateX(100%);
}

@media screen and (min-width: 992px) {
    .opap-navbar {
        height: var(--desktop-opap-nav-height);
        position: relative;
    }

    .left-dropdown-container {
        max-width: 22px;
        height: 22px;
    }

    .left-dropdown-container::before {
        content: '';
        position: absolute;
        top: -23px;
        right: -1rem;
        width: 50vw;
        height: 66px;
        background-color: var(--ps-red);
    }

    .container {
        margin: 0 auto;
        width: 100%;
        height: var(--desktop-opap-nav-height);
        justify-content: unset;
        gap: 1rem;
        position: relative;
    }

    .navbar-brand-container {
        max-width: 110px;
        padding-right: 1rem;
        margin-right: 0;
    }

    .navbar-brand-container::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 64px;
        clip-path: polygon(0 0%, 36% 0, 11% 100%, 0% 100%);
    }

    .pam-links {
        margin-right: auto;
        display: flex !important;
        align-items: center;
        gap: 0.75rem;
    }

    .pam-link {
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--disabled-text);
        text-decoration: none;
    }

    .pam-link.active {
        color: var(--ps-white);
    }

    .close {
        width: 24px;
        height: 24px;
        position: relative;
        cursor: pointer;
    }

    .close span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: var(--ps-white);
        border-radius: 0.5rem;
        left: -2px;
    }

    .close.open span:nth-child(1) {
        top: 9px;
        transform: rotate(135deg);
    }

    .close.open span:nth-child(2) {
        top: 9px;
        transform: rotate(-135deg);
    }
}

@media screen and (min-width: 1400px) {
    .pam-links {
        gap: 1.5rem;
    }
}

@media screen and (min-width: 1800px) {
    .pam-links {
        gap: 2rem;
    }
}
</style>
