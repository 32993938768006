export default {
    promos: {
        points: 'points'
    },
    leaderBoard: {
        myPoints: 'My points',
        notPlatWinners: 'There are no winners yet in the platinum level and the big prize of',
        platWinners: 'Platinum Tier Winners : ',
        reward: 'Reward per winner : ',
        platinumWinners: 'Platinum Winners'
    },
    historyCalendar: {
        previousWeek: 'Prev. Week',
        nextWeek: 'Next Week',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        totalDailyPoints: 'Total Daily Points',
        totalWeekPoints: 'Total Weekly Points',
        activationWeek: 'PROMOS',
        points: 'POINTS',
        standardWheel: 'MAGIC WHEEL',
        premiumWheel: 'PREMIUM WHEEL'
    },
    navbar: {
        pamestoixima: 'PAMESTOIXIMA',
        stoixima: 'SPORTS',
        liveStoixima: 'LIVE SPORTS',
        virtuals: 'VIRTUALS+',
        casino: 'CASINO',
        slots: 'SLOTS',
        liveCasino: 'LIVE CASINO',
        games: 'GAMES',
        visit: 'Visit',
        promotions: 'Promotions',
        casinoPromos: 'CASINO PROMOS',
        casinoPromosDescription: 'A new game offer is waiting for you!',
        opapWorld: 'OPAP World'
    },
    opapFooter: {
        sponsors: 'Sponsors',
        support: 'Support',
        company: 'Company ',
        aboutUs: 'About Us',
        aboutUsLink: 'https://club.pamestoixima.gr/en/about_us',
        casinoGames: 'Casino Games',
        casinoGamesLink: 'https://casino.pamestoixima.gr/games',
        transactionsMethods: 'Transactions Methods',
        transactionsMethodsLink: 'https://club.pamestoixima.gr/en/method_of_transactions',
        glossary: 'Glossary',
        glossaryLink: 'https://club.pamestoixima.gr/en/glossary-casino',
        usefulLinks: 'Useful Links',
        complaints: 'Complaints',
        complaintsLink: 'https://club.pamestoixima.gr/en/parapona-kataggelies',
        parapona: '',
        paraponaLink: '',
        rules: 'Rules',
        rulesLink: 'https://club.pamestoixima.gr/en/rules-casino',
        termsAndConditions: 'FAQs',
        termsAndConditionsLink: 'https://club.pamestoixima.gr/en/faq',
        privacyPolicy: 'Privacy Policy',
        privacyPolicyLink: 'https://club.pamestoixima.gr/en/privacy-policy',
        cookiesPolicy: 'Cookies Policy',
        cookiesPolicyLink: 'https://club.pamestoixima.gr/en/cookies-policy',
        contactUs: 'Contact Us',
        contactUsLink: 'https://club.pamestoixima.gr/en/contact-us',
        faqs: 'Account Verification',
        faqsLink: 'https://club.pamestoixima.gr/en/account-verification',
        responsibleGaming: 'Terms and Conditions',
        responsibleGamingLink: 'https://club.pamestoixima.gr/en/responsible_gaming',
        paymentMethods: 'Payment Methods',
        liveChat: 'Live Chat',
        accountVerification: 'Taxation',
        accountVerificationLink: 'https://club.pamestoixima.gr/en/taxation-casino',
        rensponsibleGaming: 'Responsible Gaming',
        language: 'Language (Γλώσσα)',
        eeepNotice: 'The administrator of this website is the company with the name "Organization of Football Prognostics S.A." and the distinctive title "OPAP S.A.", which is based in Greece, Athens, at 112 Leoforos Athinon Str., with registry number 46329/06 / B / 00/15. OPAP S.A. Is supervised by the Hellenic Gaming Commission (HGC) and has received a license to conduct games of chance via internet type 2 with code HGC-008-LH, for the organization of other online games, valid from May 25th, 2021 until May 25th, 2028. Regulator HGC. You will have to be over 21 years old to be allowed to participate in any gambling activities. Frequent participation in gambling could lead to addiction and loss of assets. Helpline: 1114',
        hccRegulated: 'HGC REGULATED',
        riskOfAddiction: 'RISK OF ADDICTION & ASSET LOSS',
        helpLine: 'HELPLINE KETHEA: 1114',
        smallHelpLine: 'KETHEA: 1114',
        playResponsibly: 'PLAY RESPONSIBLY',
        copyright: '© 2023 OPAP. All rights reserved.'
    },
    tier: {
        title: 'My Tier',
        bronze: 'Bronze',
        silver: 'Silver',
        gold: 'Gold',
        platinum: 'Platinum',
        myPoints: 'My Points',
        pointsToNextLevel: 'Points to next level',
        prize: 'Tier Prize'
    },
    magicWheel: {
        routeTitle: 'Magic Wheel',
        standard: 'Magic',
        premium: 'Premium',
        wheel: 'Wheel',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        points: 'POINTS',
        freeSpins: 'FREE SPINS',
        goldenChips: 'GOLDEN CHIPS',
        learnMore: 'learn more',
        freeBets: 'FREE BETS'
    },
    howToPlay: {
        text: `<div><p>
        PS  Lucky Wheel rewards you with guaranteed prizes every day, completely
        free!
    </p>
    <p>
        <strong>Participation  requirements</strong>
    </p>
    <p>
        All  you have to do in order to unlock the wheel is:
    </p>
    <p>
        If  your first deposit within the day is between €20 and €49.99, you
        activate the Lucky Wheel.
    </p>
    <p>
        If  your deposit is over €50, you activate the Premium Wheel and claim  even
        bigger prizes!
    </p>
    <p>
        The  Wheel can be activated once, every day! But it should be noted, that
        the wheel is activated within one hour after the player's deposit  and is
        active until 17:00 PM the next day. After 17:00 pm of the  following day the
        wheel is deactivated and the player loses the  chance of the daily spin.
    </p>
    <p>
        <strong>Wheel  Rewards:</strong>
    </p>
    <p>
        With  the minimum deposit (€20 - €49.99), the Lucky Wheel is activated  and
        you claim:
    </p>
    <ul>
        <li>
            <p>
                Up          to 10 Free Spins on Tome of Madness
            </p>
        </li>
        <li>
            <p>
                Up          to 10 Golden Chips on Branded tables
            </p>
        </li>
        <li>
            <p>
                Up          to €2 Free Bet in Sportsbook.
            </p>
        </li>
    </ul>
    <p>
        Accordingly,  in the Premium Wheel (deposit €50+) you claim:
    </p>
    <ul>
        <li>
            <p>
                Up          to 50 Free Spins on Tome of Madness
            </p>
        </li>
        <li>
            <p>
                Up          to 50 Golden Chips on Branded tables
            </p>
        </li>
        <li>
            <p>
                Up          to €5 Free Bet in Sportsbook.
            </p>
        </li>
    </ul>
    <p>
        Prizes  from the wheel are credited to your account automatically within 24
        hours.
    </p>
    </div>`
    }
}
