<template>
    <div class="eeep container" @click="goToExternal">
        <img
            :src="eeepLogo"
            alt=""
            class="eeep-logo"
        >
        <span class="eeep-warnings desktop-only">
            21+ | {{ $t('opapFooter.hccRegulated') }} | {{ $t('opapFooter.riskOfAddiction') }} | {{ $t('opapFooter.helpLine') }} | {{ $t('opapFooter.playResponsibly') }}
        </span>
        <span class="eeep-warnings mobile-only">
            21+ | {{ $t('opapFooter.riskOfAddiction') }} | {{ $t('opapFooter.smallHelpLine') }} | {{ $t('opapFooter.playResponsibly') }}
        </span>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                windowWidth: window.innerWidth
            }
        },
        computed: {
            eeepLogo() {
                if (this.windowWidth < 992) {
                    return require('@/assets/images/eeep-logo.svg')
                }
                return this.$store.getters.getLanguage === 'en' ? require('@/assets/images/eeep-desktop-logo-en.svg') : require('@/assets/images/eeep-desktop-logo-el.svg')
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        methods: {
            handleResize() {
                this.windowWidth = window.innerWidth
            },
            goToExternal() {
                window.open('https://certifications.gamingcommission.gov.gr/publicRecordsOnline/SitePages/KatoxoiAdeiasOnline.aspx', '_blank')
            }
        }
    }
</script>

<style scoped>
.eeep.container {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 85%;
    margin: 0 auto;
    cursor: pointer;
}

.eeep-logo {
    width: 55px;
    height: 55px;
}

.eeep-warnings {
    font-size: 0.85rem;
    line-height: 140%;
    color: var(--ps-black);
    text-align: center;
}

@media screen and (min-width: 992px) {
    .eeep.container {
        justify-content: space-around;
    }

    .eeep-logo {
        width: 120px;
        height: auto;
    }

    .eeep-warnings {
        width: 75%;
        font-size: 1.1875rem;
        line-height: 147%;
    }
}
</style>
