<template>
    <div class="dropdown">
        <button
            class="dropdown-preview"
            type="button"
            @click="toggleDropdown"
        >
            {{ languagePreview }}
        </button>
        <transition name="fade">
            <ul
                v-if="showDropdown"
                class="dropdown-options"
            >
                <li
                    v-for="language in filteredLanguages"
                    :key="language.value"
                    class="dropdown-item"

                    @click="setLanguage(language.value)"
                >{{ language.footerTitle }}</li>
            </ul>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showDropdown: false
            }
        },
        computed: {
            storedLanguage() {
                return this.$store.getters.getLanguage
            },
            languagePreview() {
                if (this.storedLanguage === 'el') {
                    return 'ΕΛ'
                }
                return 'EN'
            },
            availableLanguages() {
                return this.$store.getters.getAvailableLanguages
            },
            filteredLanguages() {
                return this.availableLanguages.filter(language => language.value !== this.storedLanguage)
            }
        },
        methods: {
            toggleDropdown() {
                this.showDropdown = !this.showDropdown
                setTimeout(() => {
                    this.showDropdown = false
                }, 3000)
            },
            setLanguage(language) {
                this.showDropdown = false
                this.$store.commit('setLanguage', language)
            }
        }
    }
</script>

<style scoped>
.dropdown {
    position: relative;
}

.dropdown-preview {
    background: transparent;
    color: var(--ps-white);
    border: none;
    font-weight: 800;
    font-size: 0.75rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.dropdown-options {
    position: absolute;
    top: 100%;
    color: var(--ps-white);
    left: 0;
    z-index: 2;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 4px;
    padding-left: 0;
    list-style: none;
    font-size: 0.75rem;
    cursor: pointer;
}

.dropdown-item {
    background: var(--ps-black);
    color: var(--ps-white);
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    font-weight: 800;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: var(--ps-white);
    color: var(--ps-black);
}

@media screen and (min-width: 992px) {
    .dropdown-preview {
        font-size: 1rem;
        width: 35px;
        height: 35px;
    }

    .dropdown-options {
        width: 35px;
        height: 35px;
        font-size: 1rem;
    }

    .dropdown-item {
        width: 35px;
        height: 35px;
    }
}
</style>
