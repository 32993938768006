import Vue from 'vue'
import VueRouter from 'vue-router'
import vueConfig from '@/../vue.config'
import jwtUtils from '@/utils/jwt-utils'
import store from '@/store'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: vueConfig.publicPath,
    routes: [
        {
            path: '/promos/:promoId',
            name: 'promo',
            component: () => import('@/components/routes/PromoLandingView'),
            meta: {
                gtm: 'promos'
            }
        },
        {
            path: '/how-to-play',
            name: 'howToPlay',
            component: () => import('@/components/routes/HowToPlayView'),
            meta: {
                title: 'Πληροφορίες Για Το MAGICLAND - Οδηγός Συμμετοχής',
                gtm: 'how-to-play',
                menuOrder: 3,
                icons: 'how-to-play'
            }
        },
        {
            path: '/history',
            name: 'history',
            component: () => import('@/components/routes/HistoryView'),
            meta: {
                title: 'Ιστορικό Πόντων & Αποστολών | MAGICLAND Στο Casino.pamestoixima.gr',
                gtm: 'history',
                menuOrder: 4,
                icons: 'profile'
            }
        },
        {
            path: '/',
            name: 'home',
            component: () => import('@/components/routes/MagicWheelView'),
            meta: {
                title: 'Τυχερός Τροχός | MAGICLAND Στο Casino.pamestoixima.gr',
                gtm: 'magic-wheel',
                menuOrder: 1,
                icon: 'home'
            }

        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return {
            x: 0,
            y: 0
        }
    }
})

router.beforeEach((to, from, next) => {
    const authToken = store.getters.getJwtToken

    if (authToken && !jwtUtils.isValid(authToken)) {
        store.dispatch('logout').then(() => {
            if (from.path !== '/' || from.path !== '/how-to-play') {
                next({ path: '/' })
            }
        })
    }

    if (!store.getters.isLoggedIn && (to.path !== '/' && to.path !== '/how-to-play')) {
        console.log('Not logged in, redirecting to home')
        if (typeof window !== 'undefined' && window.openSingInAsPopup) {
            window.openSingInAsPopup()
        }
        next({ path: '/' })
    }

    if (!store.getters.isLoggedIn && to.path === '/') {
        if (typeof window !== 'undefined' && window.openSingInAsPopup) {
            window.openSingInAsPopup()
        }
    }

    next()
})

export default router
