<template>
    <div id="app">
        <opap-navbar />
        <desktop-navbar-top class="desktop-only" />
        <page-loader
            v-if="!theming || !loadingDelayPassed"
            class="page-loader"
        />
        <div
            v-if="theming"
            class="app-content"
            :style="{
                backgroundColor: theming.colors.other.defaultBackgroundColor,
                backgroundImage: `url(${theming.images.mainBackground.bottomLeft}) ,
                url(${theming.images.mainBackground.bottomRight}) ,
                url(${theming.images.mainBackground.topLeft}) ,
                url(${theming.images.mainBackground.topRight}),
                url(${theming.images.mainBackground.backCenter})`,
            }"
        >
            <transition
                :name="$route.meta.transition || 'fade'"
                mode="out-in"
            >
                <router-view />
            </transition>
        </div>

        <opap-footer />
        <mobile-navbar-bottom class="mobile-only" />
        <div
            id="login"
            data-game-type="casino"
            data-widget-host="club"
            data-config-channel="WEB"
        />
        <div
            id="opap-notifications"
            data-game-type="casino"
            data-widget-host="club"
            data-config-channel="WEB"
            :config-deeplinkurl="`${opapWidgetAccountDomain}/my-account/`"
        />
        <div
            id="register"
            data-game-type="casino"
            data-widget-host="club"
            data-config-channel="WEB"
        />
        <div
            id="termsAndConditions"
            data-game-type="casino"
            data-widget-host="club"
            data-config-channel="WEB"
        />
        <div
            id="kyc"
            data-game-type="casino"
            data-widget-host="club"
            data-config-channel="WEB"
        />
        <div
            id="emailVerification"
            data-game-type="casino"
            data-widget-host="club"
            data-config-channel="WEB"
        />
        <iframe v-if="frameSrc" id="ssoFrame" hidden="hidden" :src="frameSrc" title="ssoFrame" />
    </div>
</template>
<script>
    import OpapNavbar from '@/components/opap-navbar/OpapNavbar'
    import DesktopNavbarTop from '@/components/DesktopNavbarTop'
    import OpapFooter from '@/components/opap-footer/OpapFooter'
    import MobileNavbarBottom from '@/components/MobileNavbarBottom'

    import PageLoader from '@/components/PageLoader'
    import configMixin from '@/mixins/config'
    import PubSub from 'pubsub-js'
    import sessionService from '@/services/session-service'
    import jwtUtils from '@/utils/jwt-utils'
    import themingService from '@/services/theming-service'
    export default {
        name: 'App',
        components: {
            OpapFooter,
            DesktopNavbarTop,
            MobileNavbarBottom,
            OpapNavbar,
            PageLoader
        },
        mixins: [configMixin],
        data() {
            return {
                loadingDelayPassed: false,
                authorizeBaseUrl: process.env.VUE_APP_SSO_HOST + '/web/access/oidc/authorize',
                authParams: {
                    client_id: process.env.VUE_APP_SSO_CLIENT_ID,
                    response_type: 'code',
                    scope: 'openid',
                    redirect_uri: window.location.protocol + '//' + window.location.host + '/oidc/callback'
                },
                frameSrc: null,
                opapWidgetAccountDomain: process.env.VUE_APP_OPAP_WIDGET_ACCOUNT_URL,
                theming: null
            }
        },
        computed: {
            getJwtToken() {
                return this.$store.getters.getJwtToken
            },
            isLoggedIn() {
                return this.$store.getters.isLoggedIn
            },
            hostname() {
                return window.location.protocol + '//' + window.location.host
            },
            mainBackgroundClass() {
                return {
                    backgroundImage: `url(${this.theming?.mainBackground?.bottomLeft}) ,
                    url(${this.theming?.mainBackground?.bottomRight}) ,
                    url(${this.theming?.mainBackground?.topLeft}) ,
                    url(${this.theming?.mainBackground?.topRight}) ,
                    url(${this.theming?.mainBackground?.center})`
                }
            },
            calculateAuthorizeUrl() {
                const params = { ...this.authParams, state: this.$router.currentRoute.name }
                const searchParams = new URLSearchParams(params)
                return this.authorizeBaseUrl + '?' + searchParams.toString()
            }
        },
        watch: {
            theming: {
                deep: true,
                handler() {
                    this.setCssVars()
                }
            }
        },
        beforeCreate() {
            themingService.getTheming().then(response => {
                this.theming = response
                this.$store.commit('setTheming', response)
                setTimeout(() => {
                    this.loadingDelayPassed = true
                }, 500)
            })
        },
        mounted() {
            if (!sessionStorage.getItem('selectedLanguage')) {
                this.$store.commit('setLanguage', 'el')
            }
            this.initializeExternalScripts()
            this.initializeExternalStyles()
            this.initializeSubscribers()
            this.listenAuthCallback()
        },
        beforeDestroy() {
            window.removeEventListener('message', this.authCallbackHandler)
        },
        methods: {
            initializeAppData() {
                this.$store.dispatch('refreshCurrentSession')
                this.$store.dispatch('refreshAvailableCampaign')
            },
            enableFrame() {
                if (!this.frameSrc) {
                    this.frameSrc = this.calculateAuthorizeUrl
                }
            },
            disableFrame() {
                if (this.frameSrc) {
                    this.frameSrc = null
                }
            },
            shouldReAuthorize() {
                return this.getJwtToken && jwtUtils.jwtExpiresInSeconds(this.getJwtToken, 120)
            },
            listenAuthCallback() {
                window.addEventListener('message', this.authCallbackHandler)
            },
            async authCallbackHandler(event) {
                if (event.origin !== window.origin) return

                if (event.data.search === undefined) return
                const callbackQueryParams = new URLSearchParams(event.data.search)
                const code = callbackQueryParams.get('code')
                if (!code) {
                    console.log('Code from auth callback is empty')
                    return
                }
                sessionService.login(code).then(response => {
                    this.$store.dispatch('login', response)
                    this.initializeAppData()
                    this.disableFrame()
                })
                    .catch(error => {
                        console.log(error)
                        this.disableFrame()
                    })
            },
            initializeExternalScripts() {
                const scripts = [
                    `${this.config.widgetBaseUrl}/nlsWebConfig.js`,
                    `${this.config.widgetBaseUrl}/player-login-widget/static/config/loginConfig.js`,
                    `${this.config.widgetBaseUrl}/player-login-widget/static/js/login_vendor.js`,
                    `${this.config.widgetBaseUrl}/player-login-widget/static/js/login.js`,
                    `${this.config.widgetBaseUrl}/player-login-widget/static/js/notifications.js`,
                    `${this.config.widgetBaseUrl}/player-termsAndConditions-widget/static/js/termsAndConditions_vendor.js`,
                    `${this.config.widgetBaseUrl}/player-termsAndConditions-widget/static/js/termsAndConditions.js`,
                    `${this.config.widgetBaseUrl}/player-register-widget/static/config/registrationConfig.js`,
                    `${this.config.widgetBaseUrl}/player-register-widget/static/js/registration_vendor.js`,
                    `${this.config.widgetBaseUrl}/player-register-widget/static/js/registration.js`,
                    `${this.config.widgetBaseUrl}/player-loggedIn-widget/static/config/loggedInConfig.js`,
                    `${this.config.widgetBaseUrl}/player-loggedIn-widget/static/js/loggedIn_vendor.js`,
                    `${this.config.widgetBaseUrl}/player-loggedIn-widget/static/js/loggedIn.js`,
                    `${this.config.widgetBaseUrl}/player-kyc-widget/static/config/kycConfig.js`,
                    `${this.config.widgetBaseUrl}/player-kyc-widget/static/js/kyc_vendor.js`,
                    `${this.config.widgetBaseUrl}/player-kyc-widget/static/js/kyc.js`,
                    `${this.config.widgetBaseUrl}/player-email-verification-widget/static/config/emailVerificationConfig.js`,
                    `${this.config.widgetBaseUrl}/player-email-verification-widget/static/js/emailVerification_vendor.js`,
                    `${this.config.widgetBaseUrl}/player-email-verification-widget/static/js/emailVerification.js`,
                    'https://button.sandbox.torawallet.gr/tora/checkout.js'
                ]
                scripts.forEach(script => {
                    const tag = document.createElement('script')
                    tag.setAttribute('src', script)
                    document.body.appendChild(tag)
                })
                this.initializeGoogleOptimizer()
            },
            initializeGoogleOptimizer() {
                const googleOptimizer = document.createElement('script')
                googleOptimizer.setAttribute('src', `https://www.googleoptimize.com/optimize.js?id=${this.config.googleOptimizerId}`)
                document.head.appendChild(googleOptimizer)
            },
            initializeExternalStyles() {
                const styles = [
                    `${this.config.widgetCssBaseUrl}/player-login-widget/static/css/login.css`,
                    `${this.config.widgetCssBaseUrl}/player-login-widget/static/css/notifications.css`,
                    `${this.config.widgetCssBaseUrl}/player-termsAndConditions-widget/static/css/termsAndConditions.css`,
                    `${this.config.widgetCssBaseUrl}/player-register-widget/static/css/registration.css`,
                    `${this.config.widgetCssBaseUrl}/player-loggedIn-widget/static/css/loggedIn.css`,
                    `${this.config.widgetCssBaseUrl}/player-kyc-widget/static/css/kyc.css`
                ]
                styles.forEach(stylesheet => {
                    const file = document.createElement('link')
                    file.rel = 'stylesheet'
                    file.href = stylesheet
                    document.head.appendChild(file)
                })
            },
            initializeSubscribers() {
                const eventsToSubscribe = [
                    'VALID_USER_SESSION',
                    'NO_USER_SESSION',
                    'SUCCESSFUL_LOGIN',
                    'platform/loggedOut',
                    'platform/sessionTimeout',
                    'updateBalance',
                    'CREATE_ACCOUNT'
                ]
                const eventCallbacks = [
                    this.onValidUserSession,
                    this.onNoUserSession,
                    this.onSuccessfulLogin,
                    this.onLoggedOut,
                    this.onSessionTimeout,
                    this.onUpdateBalance,
                    this.onCreateAccount
                ]
                eventsToSubscribe.forEach((event, index) => {
                    PubSub.subscribe(event, eventCallbacks[index])
                })
            },
            setCssVars() {
                const bodyStyles = document.body.style
                bodyStyles.setProperty('--main-color', this.theming.colors.other.mainColor)
                bodyStyles.setProperty('--secondary-color', this.theming.colors.other.secondaryColor)
                bodyStyles.setProperty('--default-text-color', this.theming.colors.other.defaultTextColor)
                bodyStyles.setProperty('--default-background-color', this.theming.colors.other.defaultBackgroundColor)
                bodyStyles.setProperty('--cta-text-color', this.theming?.colors?.other?.ctaTextColor || '#FFFFFF')
                bodyStyles.setProperty('--cta-background-color', this.theming?.colors?.other?.ctaBackgroundColor || '#C3AC68'
                )
                bodyStyles.setProperty('--route-title-text-color', this.theming.colors.other.routeTitleTextColor)
                bodyStyles.setProperty('--route-title-background-color', this.theming.colors.other.routeTitleBackgroundColor)
            },
            onValidUserSession() {
                this.$store.commit('setLoggedInPlayerUsername', sessionStorage.getItem('loggedInPlayerUsername'))
                // Ιf we need to refresh the user it should check we OR the shouldAuthorize
                if (!this.isLoggedIn) {
                    this.enableFrame()
                }
            },
            onNoUserSession() {
                this.logout()
                window.addEventListener('load', () => {
                    if (this.$route.path === '/how-to-play') {
                        return
                    }
                    window.openSingInAsPopup()
                })
            },
            onSuccessfulLogin() {
                this.enableFrame()
            },
            onLoggedOut() {
                this.logout()
                this.$router.push('/')
            },
            onSessionTimeout() {
                this.logout()
                this.$router.push('/')
            },
            logout() {
                this.$store.dispatch('logout')
            },
            onUpdateBalance() {
                // console.log('updateBalance')
            },
            onCreateAccount() {
                // console.log('CREATE_ACCOUNT')
            }
        }
    }
</script>
<style>
@import '~@/assets/styles/global.css';

.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-content {
    min-height: calc(100vh - var(--mobile-opap-nav-height));
    width: 100%;
    position: relative;
    background-repeat: no-repeat, no-repeat, repeat-y, repeat-y, no-repeat;
    background-position: bottom left, bottom right, top left, top right, center center;
    background-size: 100% auto, 100% auto, 20vw auto, 20vw auto, cover;
}

@media screen and (min-width: 1200px) {
    .app-content {
        background-repeat: no-repeat, no-repeat, repeat-y, repeat-y, no-repeat;
        background-position: bottom left, bottom right, top left, top right, center center;
        background-size: auto 30vh, auto 30vh, 350px auto, 350px auto, cover;
    }
}
</style>
