export default {
    state: {
        dailyParticipation: null,
        isModalOpen: false
    },
    mutations: {
        setDailyParticipation(state, dailyParticipation) {
            state.dailyParticipation = dailyParticipation
        },
        setIsModalOpen(state, isModalOpen) {
            state.isModalOpen = isModalOpen
        }
    }
}
