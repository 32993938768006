import restAPI from '@/utils/rest-api'
export default {
    getAvailable() {
        return restAPI({
            url: '/campaigns/available',
            method: 'get'
        })
    },
    getLeaderboard() {
        return restAPI({
            url: '/campaigns/leaderboard',
            method: 'get'
        })
    },
    fetchPointsHistory() {
        return restAPI({
            url: '/campaigns/total-points',
            method: 'get'
        })
    }
}
