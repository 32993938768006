<template>
    <footer class="main-footer">
        <div class="sponsors container">
            <span class="payment-title">{{ $t('opapFooter.sponsors') }}</span>
            <div class="sponsors-container">
                <a
                    v-for="sponsor in sponsorList"
                    :key="sponsor.name"
                    :href="sponsor.link"
                    target="_blank"
                >
                    <img
                        class="sponsor-image"
                        :src="sponsor.image"
                        :alt="sponsor.name"
                    >
                </a>
            </div>
        </div>
        <div class="link-list container">
            <link-list
                :links-title="$t('opapFooter.company')"
                :links="companyLinks"
            />
            <link-list
                :links-title="$t('opapFooter.support')"
                :links="supportLinks"
            />
            <link-list
                :links-title="$t('opapFooter.casinoGames')"
                :links="casinoGameLinks"
            />
            <link-list
                :links-title="$t('opapFooter.usefulLinks')"
                :links="usefulLinks"
            />
            <div class="language-sitemap-container ">
                <div class="links-title">
                    <img
                        :src="require('@/assets/images/globe.svg')"
                        class="language-icon"
                        alt="language select"
                    > {{ $t('opapFooter.language') }}
                </div>
                <div class="language-select">
                    <footer-language-select />
                </div>
            </div>
        </div>
        <div class="divider container" />
        <div class="social-mobile container">
            <div class="copyright-row">
                <div class="social-icons">
                    <a
                        v-for="icon in socialIcons"
                        :key="icon.name"
                        :href="icon.link"
                        target="_blank"
                    >
                        <img
                            :src="icon.image"
                            :alt="icon.name"
                            class="social-icon-image"
                        >
                    </a>
                </div>
                <div class="copyright-text">
                    <p> {{ $t('opapFooter.copyright') }}</p>
                </div>
            </div>
        </div>
        <div class="legal container">
            <div class="eeep-notice-container">
                <img
                    :src="eeepNotice.image"
                    :alt="eeepNotice.alt"
                >
                <div class="eeep-notice-info">
                    <p>
                        {{ eeepNotice.text }}
                    </p>
                </div>
            </div>
            <div class="legal-icons">
                <a
                    v-for="icon in legalIcons"
                    :key="icon.name"
                    :href="icon.link"
                    target="_blank"
                    class="legal-icon"
                >
                    <img
                        :alt="icon.name"
                        :src="icon.image"
                    >
                </a>
            </div>
        </div>
        <div class="divider container" />
        <div class="payment-methods container">
            <span class="payment-title">{{ $t('opapFooter.paymentMethods') }}</span>
            <div class="payment-providers-container">
                <div
                    v-for="method in paymentMethods"
                    :key="method.name"
                >
                    <img
                        :src="method.image"
                        :alt="method.name"
                    >
                </div>
            </div>
        </div>
        <div class="eeep-section eeep">
            <eeep-banner />
        </div>
    </footer>
</template>

<script>
    import LinkList from '@/components/opap-footer/LinkList'
    import FooterLanguageSelect from '@/components/opap-footer/FooterLanguageSelect'
    import EeepBanner from '@/components/opap-footer/EeepBanner'
    export default {
        components: {
            LinkList,
            FooterLanguageSelect,
            EeepBanner
        },
        data() {
            return {
                sponsorList: [
                    {
                        name: 'SEGAS',
                        link: 'http://www.segas.gr/index.php',
                        image: require('@/assets/images/sponsors/segas-logo.png')
                    },
                    {
                        name: 'KAE PAO',
                        link: 'https://www.paobc.gr/page.ashx?pid=1&lang=2',
                        image: require('@/assets/images/sponsors/kae-pao-logo.png')
                    },
                    {
                        name: 'AEK',
                        link: 'https://www.aekfc.gr',
                        image: require('@/assets/images/sponsors/aek-logo.png')
                    },
                    {
                        name: 'Epo',
                        link: 'https://www.epo.gr/Default.aspx?a_id=48231',
                        image: require('@/assets/images/sponsors/epo-logo.png')
                    },
                    {
                        name: 'Volley PAO',
                        link: 'https://www.pao1908.com/',
                        image: require('@/assets/images/sponsors/volley-pao-logo.png')
                    }

                ],
                companyLinks: [
                    {
                        label: this.$t('opapFooter.aboutUs'),
                        link: this.$t('opapFooter.aboutUsLink')
                    },
                    {
                        label: this.$t('opapFooter.responsibleGaming'),
                        link: this.$t('opapFooter.responsibleGamingLink')
                    },
                    {
                        label: this.$t('opapFooter.cookiesPolicy'),
                        link: this.$t('opapFooter.cookiesPolicyLink')
                    },
                    {
                        label: this.$t('opapFooter.privacyPolicy'),
                        link: this.$t('opapFooter.privacyPolicyLink')
                    }
                ],
                supportLinks: [
                    {
                        label: this.$t('opapFooter.contactUs'),
                        link: this.$t('opapFooter.contactUsLink')
                    },
                    {
                        label: this.$t('opapFooter.termsAndConditions'),
                        link: this.$t('opapFooter.termsAndConditionsLink')
                    },
                    {
                        label: this.$t('opapFooter.faqs'),
                        link: this.$t('opapFooter.faqsLink')
                    },
                    {
                        label: this.$t('opapFooter.accountVerification'),
                        link: this.$t('opapFooter.accountVerificationLink')
                    }

                ],
                casinoGameLinks: [
                    {
                        label: this.$t('opapFooter.rules'),
                        link: this.$t('opapFooter.rulesLink')
                    },
                    {
                        label: this.$t('opapFooter.casinoGames'),
                        link: this.$t('opapFooter.casinoGamesLink')
                    },
                    {
                        label: this.$t('opapFooter.glossary'),
                        link: this.$t('opapFooter.glossaryLink')
                    },
                    {
                        label: this.$t('opapFooter.transactionsMethods'),
                        link: this.$t('opapFooter.transactionsMethodsLink')
                    }
                ],
                usefulLinks: [

                    {
                        label: this.$t('opapFooter.complaints'),
                        link: this.$t('opapFooter.complaintsLink')
                    },
                    {
                        label: this.$t('opapFooter.parapona'),
                        link: this.$t('opapFooter.paraponaLink')
                    }
                ],
                paymentMethods: [
                    {
                        name: 'Opap Stores',
                        image: require('@/assets/images/payment-methods/opap-stores.png')
                    },
                    {
                        name: 'Visa',
                        image: require('@/assets/images/payment-methods/visa.png')
                    },
                    {
                        name: 'Skrill',
                        image: require('@/assets/images/payment-methods/skrill.png')
                    },
                    {
                        name: 'Mastercard',
                        image: require('@/assets/images/payment-methods/mastercard.png')
                    },
                    {
                        name: 'Paypal',
                        image: require('@/assets/images/payment-methods/paypal.png')
                    },
                    {
                        name: 'Maestro',
                        image: require('@/assets/images/payment-methods/maestro.png')
                    },
                    {
                        name: 'PaySafeCard',
                        image: require('@/assets/images/payment-methods/paysafe-card.png')
                    },
                    {
                        name: 'Instant Banking',
                        image: require('@/assets/images/payment-methods/instant-banking.png')
                    }
                ],
                socialIcons: [
                    {
                        name: 'Facebook',
                        link: 'https://www.facebook.com/pamestoixima.gr.official/',
                        image: require('@/assets/images/social-icons/facebook.png')
                    },
                    {
                        name: 'Instagram',
                        link: '#',
                        image: require('@/assets/images/social-icons/instagram.png')
                    },
                    {
                        name: 'Youtube',
                        link: 'https://www.youtube.com/opapsa',
                        image: require('@/assets/images/social-icons/youtube.png')
                    }
                ],
                eeepNotice: {
                    image: require('@/assets/images/legal-icons/21+.png'),
                    alt: '21+ age',
                    text: this.$t('opapFooter.eeepNotice')
                },
                legalIcons: [
                    {
                        name: 'play responsibly',
                        link: 'https://www.opapcsr.gr/our-commitment/   ',
                        image: require('@/assets/images/legal-icons/play-responsibly.png')
                    },
                    {
                        name: 'wla',
                        link: 'https://www.world-lotteries.org/',
                        image: require('@/assets/images/legal-icons/wla.png')
                    },
                    {
                        name: 'secure trust',
                        link: 'https://sealserver.trustwave.com/cert.php?customerId=24f32c9168ec4a6c9156e3eff9284656',
                        image: require('@/assets/images/legal-icons/secure-trust.png')
                    },
                    {
                        name: 'european lotteries',
                        link: 'https://www.european-lotteries.org/',
                        image: require('@/assets/images/legal-icons/european-lotteries.png')
                    },
                    {
                        name: 'kethea',
                        link: 'https://www.kethea.gr/',
                        image: require('@/assets/images/legal-icons/kethea.png')
                    }

                ]
            }
        },
        computed: {
            getLanguage() {
                return this.$store.getters.getLanguage
            }
        },
        watch: {
            getLanguage: {
                deep: true,
                immediate: true,
                handler(value) {
                    if (value === 'en') {
                        this.legalIcons[0].image = require('@/assets/images/legal-icons/play-responsibly-en.png')
                        return
                    }
                    this.legalIcons[0].image = require('@/assets/images/legal-icons/play-responsibly.png')
                }
            }
        }

    }
</script>

<style scoped>
footer {
    background-color: var(--ps-white);
    color: var(--ps-black);
    padding-bottom: 4rem;
}

.container {
    margin: 0 auto;
}

.divider {
    border-bottom: 1px solid #c2c2c2ab;
    width: 85%;
    margin: 1rem auto;
}

.sponsors.container {
    text-align: center;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsors-container {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    width: 80%;
}

.sponsor-image {
    width: 100%;
    max-width: 45px;
}

.payment-methods.container {
    text-align: center;
    padding: 1rem 0;
    width: 85%;
    margin: 0 auto;
}

.payment-title {
    font-size: 1rem;
    font-weight: 500;
    color: var(--ps-black);
}

.payment-providers-container {
    margin: 1rem auto 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 90%;
}

.opap-title {
    font-weight: 500;
    padding: 1rem 0 0 1rem;
}

.link-list.container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5rem;
    row-gap: 2rem;
    width: 80%;
    margin: 0 auto;
}

.language-sitemap-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
}

.links-title {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.footer-link {
    text-decoration: none;
    color: var(--ps-black);
}

.social-mobile.container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.copyright-row {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.social-icons {
    display: flex;
    gap: 1.7rem;
}

.social-icon-image {
    width: 1.5rem;
    height: 1.5rem;
}

.copyright-text {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.4px;
    color: var(--ps-black);
}

.legal.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 80%;
    margin: 0 auto;
}

.eeep-notice-container {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.eeep-notice {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.eeep-notice-image {
    width: 39px;
    height: 39px;
}

.eeep-notice-info {
    font-size: 0.625rem;
    color: #495057;
    line-height: 155%;
}

.legal-icons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.legal-icon {
    max-width: 100px;
}

.legal-icon > img {
    width: 100%;
}

.eeep-section {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: var(--ps-white);
    border-top: 1px solid #c2c2c2ab;
}

@media screen and (min-width: 992px) {
    footer {
        padding-bottom: 0;
    }

    .sponsors-container {
        margin-top: 2rem;
        justify-content: center;
    }

    .sponsors-container a img {
        max-height: unset;
    }

    .payment-providers-container {
        margin-top: 2rem;
        justify-content: center;
        gap: 1.75rem;
    }

    .link-list.container {
        display: flex;
        justify-content: space-between;
    }

    .copyright-row {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .social-icons {
        order: 1;
    }

    .social-mobile.container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }

    .language-sitemap-container {
        gap: 0.5rem;
    }

    .eeep-notice {
        display: flex;
        align-items: center;
        gap: 3rem;
    }

    .eeep-notice-image {
        width: unset;
        height: unset;
    }

    .sponsors.container {
        text-align: center;
        padding: 1rem 0.75rem;
    }

    .eeep-section {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-top: 1px solid #000;
    }
}
</style>
