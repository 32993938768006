<template>
    <div class="dropdown-container">
        <div
            class="logo-container"
        >
            <img
                class="logo"
                :src="require('@/assets/images/nav-icons/mobile-logo.png')"
                alt="logo"
            >
            <span>{{ $t('navbar.pamestoixima') }}</span>
        </div>
        <div class="nav-container">
            <a
                :href="stoiximaLink"
                target="_blank"
                class="nav-button"
            >
                <div class="round-container">
                    <img
                        alt="sports icon"
                        :src="require('@/assets/images/nav-icons/sports-icon.svg')"
                    >
                </div>
                <span>
                    {{ $t('navbar.stoixima') }}
                </span>
            </a>
        </div>
        <div class="nav-container">
            <a
                href="https://www.pamestoixima.gr/inplay"
                target="_blank"
                class="nav-button"
            >
                <div class="round-container">
                    <img
                        alt="live sports icon"
                        :src="require('@/assets/images/nav-icons/sports-icon.svg')"
                    >
                </div>
                <span>
                    {{ $t('navbar.liveStoixima') }}
                </span>
            </a>
        </div>
        <div class="nav-container">
            <a
                :href="virtualsLink"
                target="_blank"
                class="nav-button"
            >
                <div class="round-container">
                    <img
                        alt="virtual sports icon"
                        :src="require('@/assets/images/nav-icons/sports-icon.svg')"
                    >
                </div>
                <span>
                    {{ $t('navbar.virtuals') }}
                </span>
            </a>
        </div>
        <div class="nav-container">
            <a
                href="https://casino.pamestoixima.gr/home"
                target="_blank"
                class="nav-button"
            >
                <div class="round-container">
                    <img
                        alt="casino icon"
                        :src="require('@/assets/images/nav-icons/casino-icon.svg')"
                    >
                </div>
                <span>
                    {{ $t('navbar.casino') }}

                </span>
            </a>
        </div>
        <div class="nav-container">
            <a
                href="https://casino.pamestoixima.gr/games"
                target="_blank"
                class="nav-button"
            >
                <div class="round-container">
                    <img
                        alt="slots icon"
                        :src="require('@/assets/images/nav-icons/slots-icon.svg')"
                    >
                </div>
                <span>
                    {{ $t('navbar.slots') }}

                </span>
            </a>
        </div>
        <div class="nav-container">
            <a
                href="https://casino.pamestoixima.gr/live-games"
                target="_blank"
                class="nav-button"
            >
                <div class="round-container">
                    <img
                        alt="live casino icon"
                        :src="require('@/assets/images/nav-icons/live-casino-icon.png')"
                    >
                </div>
                <span>
                    {{ $t('navbar.liveCasino') }}
                </span>
            </a>
        </div>
        <div class="nav-container">
            <a
                href="https://casino.pamestoixima.gr/new-generation-games"
                target="_blank"
                class="nav-button"
            >
                <div class="round-container">
                    <img
                        alt="games icon"
                        :src="require('@/assets/images/nav-icons/games-icon.svg')"
                    >
                </div>
                <span>
                    {{ $t('navbar.games') }}
                </span>
            </a>
        </div>
        <router-link
            to="/promos"
            class="casino-promos"
        >
            <img
                alt="casino promos icon"
                :src="require('@/assets/images/nav-icons/casino-promos.png')"
            >
            <span class="title">
                {{ $t('navbar.casinoPromos') }}
            </span>
            <span class="description">
                {{ $t('navbar.casinoPromosDescription') }}
            </span>
        </router-link>
        <span class="divider" />
        <div class="nav-container">
            <a
                :href="opapLink"
                target="_blank"
                class="nav-button opap-btn"
            >
                <div class="round-container">
                    <img
                        alt="opap icon"
                        :src="require('@/assets/images/nav-icons/opap-logo-icon.svg')"
                    >
                </div>
                <span>{{ $t('navbar.visit') }} {{ $t('navbar.opapWorld') }}</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            isGreek() {
                return this.$store.getters.getLanguage === 'el'
            },
            stoiximaLink() {
                return this.isGreek ? 'https://www.pamestoixima.gr/home' : 'https://virtuals.pamestoixima.gr/en/virtuals'
            },
            virtualsLink() {
                return this.isGreek ? 'https://virtuals.pamestoixima.gr/games/virtuals' : 'https://virtuals.pamestoixima.gr/en/virtuals'
            },
            opapLink() {
                return this.isGreek ? 'https://www.opap.gr' : 'https://www.opap.gr/en'
            }
        }
    }
</script>

<style scoped>
.dropdown-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 5%;
    width: 248px;
    z-index: 2;
    background: var(--dropdown-bg);
    color: var(--ps-white);
    padding: 0.75rem;
    border-radius: 0.5rem;
    box-shadow: 0 16px 16px rgba(30, 31, 36, 0.08), 0 32px 64px rgba(3, 15, 55, 0.16);
}

.logo-container {
    display: flex;
    width: max-content;
    gap: 0.5rem;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    letter-spacing: 0.5px;
}

.logo {
    width: 24px;
    margin: auto;
    display: block;
}

.nav-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
}

.nav-container:last-child {
    margin-top: 0.5rem;
}

.nav-button {
    border: 1px solid #696f81;
    width: 100%;
    min-height: 45px;
    border-radius: 8px;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: var(--ps-white);
}

.round-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--ps-white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-button.mobile-only {
    justify-content: flex-start;
    gap: 0.75rem;
    padding: 0;
}

.casino-promos {
    margin-top: 1rem;
    width: 100%;
    position: relative;
    text-decoration: none;
    color: var(--ps-white);
}

.casino-promos img {
    width: 100%;
    object-fit: cover;
    height: auto;
}

.casino-promos .title {
    position: absolute;
    top: 15%;
    left: 9%;
}

.casino-promos .description {
    position: absolute;
    top: 42%;
    left: 9%;
}

.divider {
    width: 100%;
    border-bottom: 1px solid #575c6b;
    padding: 0.5rem;
}

.nav-button.opap-btn {
    font-weight: 700;
    color: var(--ps-white);
}

.nav-button.opap-btn img {
    width: 32px;
    height: 24px;
}

@media screen and (min-width: 992px) {
    .dropdown-container {
        top: 105%;
        left: 0;
    }
}
</style>
