export default {
    state: {
        loggedInPlayerUsername: sessionStorage.getItem('loggedInPlayerUsername'),
        jwtToken: sessionStorage.getItem('opapCasinoToken')
    },
    mutations: {
        setJwtToken(state, jwtToken) {
            state.jwtToken = jwtToken
        },
        setLoggedInPlayerUsername(state, username) {
            state.loggedInPlayerUsername = username
        }
    },
    actions: {
        login({ dispatch }, payload) {
            sessionStorage.setItem('opapCasinoToken', payload.token)
            dispatch('setJwtToken', payload.token)
        },
        setJwtToken({ commit }, jwtToken) {
            if (jwtToken) {
                sessionStorage.setItem('opapCasinoToken', jwtToken)
                commit('setJwtToken', jwtToken)
            } else {
                commit('setJwtToken', null)
            }
        },
        logout({ commit }) {
            sessionStorage.removeItem('opapCasinoToken')
            sessionStorage.removeItem('loggedInPlayerId')
            sessionStorage.removeItem('loggedInPlayerUsername')
            commit('setJwtToken', null)
        }
    }
}
