const baseUrl = process.env.VUE_APP_HOST_DOMAIN
const BundleAnalyzerPlugin = require('webpack-bundle-analyzer').BundleAnalyzerPlugin
module.exports = {
    publicPath: '/',
    configureWebpack: {
        plugins: [new BundleAnalyzerPlugin({
            analyzerMode: 'disabled'
        })]
    },
    pluginOptions: {
        sitemap: {
            urls: [
                {
                    loc: baseUrl + '/',
                    changefreq: 'monthly'
                },
                {
                    loc: baseUrl + '/promos',
                    changefreq: 'daily'
                },
                {
                    loc: baseUrl + '/how-to-play',
                    changefreq: 'monthly'
                },
                {
                    loc: baseUrl + '/history',
                    changefreq: 'daily'
                },
                {
                    loc: baseUrl + '/my-profile',
                    changefreq: 'daily'
                }
            ]
        }
    }
}
