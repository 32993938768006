import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import VueI18n from 'vue-i18n'
import el from '@/locale/el'
import en from '@/locale/en'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueDOMPurifyHTML from 'vue-dompurify-html'

import { faRotateRight, faRotate, faXmark, faChevronLeft, faChevronRight, faTimes, faCoins, faEuroSign, faGift } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import configMixin from '@/mixins/config'
import toasterMixin from '@/mixins/toaster-mixin'
import VueMeta from 'vue-meta'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false
})

Vue.use(VueI18n)
Vue.use(Vuex)
Vue.use(VueMeta)
Vue.mixin(configMixin)
Vue.mixin(toasterMixin)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueDOMPurifyHTML, {
    default: {
        ALLOWED_TAGS: [
            'iframe',
            'img',
            'br',
            'span',
            'p',
            'pre',
            'strong',
            'blockquote',
            'ul',
            'a',
            'ol',
            'li',
            'em',
            'i',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6'
        ],
        ADD_ATTR: ['src', 'target', 'allowfullscreen', 'frameborder', 'allow', 'allow-forms', 'allow-same-origin', 'class']
    }
})

Vue.filter('zerofill', (value) => {
    return (value < 10 && value > -1 ? '0' : '') + value
})

library.add(faRotateRight, faRotate, faXmark, faChevronLeft, faChevronRight, faTimes, faStar, faCoins, faEuroSign, faGift)

Vue.config.productionTip = false

const messages = { el, en }
const i18n = new VueI18n({
    locale: store.getters.getLanguage || 'el',
    messages
})

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
