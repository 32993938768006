import sessionService from '@/services/session-service'
export default {
    state: {
        currentSession: null,
        tierList: [],
        currentTier: {}
    },
    mutations: {
        setTierList(state, tiers) {
            state.tierList = tiers
        },
        setCurrentTier(state, tier) {
            state.currentTier = tier
        },
        setCurrentSession(state, session) {
            state.currentSession = session
        }
    },
    actions: {
        async refreshCurrentSession({ commit, dispatch }) {
            const currentSession = await sessionService.getCurrentSession()
            commit('setCurrentSession', currentSession)
            commit('setTierList', currentSession.tiers)
            dispatch('refreshCurrentTier')
        },
        refreshCurrentTier({ commit, state }) {
            let tierList = state.tierList
            tierList = [
                {
                    tierType: 'NO_TIER',
                    minPoints: 0,
                    maxPoints: tierList[0].minPoints - 1
                },
                ...tierList
            ]
            const currentSession = state.currentSession
            if (tierList && currentSession) {
                const currentTier = tierList.find(tier => tier.minPoints <= currentSession.totalPoints && tier.maxPoints >= currentSession.totalPoints)
                commit('setCurrentTier', currentTier)
            }
        }
    }
}
