var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('opap-navbar'),_c('desktop-navbar-top',{staticClass:"desktop-only"}),(!_vm.theming || !_vm.loadingDelayPassed)?_c('page-loader',{staticClass:"page-loader"}):_vm._e(),(_vm.theming)?_c('div',{staticClass:"app-content",style:({
            backgroundColor: _vm.theming.colors.other.defaultBackgroundColor,
            backgroundImage: `url(${_vm.theming.images.mainBackground.bottomLeft}) ,
            url(${_vm.theming.images.mainBackground.bottomRight}) ,
            url(${_vm.theming.images.mainBackground.topLeft}) ,
            url(${_vm.theming.images.mainBackground.topRight}),
            url(${_vm.theming.images.mainBackground.backCenter})`,
        })},[_c('transition',{attrs:{"name":_vm.$route.meta.transition || 'fade',"mode":"out-in"}},[_c('router-view')],1)],1):_vm._e(),_c('opap-footer'),_c('mobile-navbar-bottom',{staticClass:"mobile-only"}),_c('div',{attrs:{"id":"login","data-game-type":"casino","data-widget-host":"club","data-config-channel":"WEB"}}),_c('div',{attrs:{"id":"opap-notifications","data-game-type":"casino","data-widget-host":"club","data-config-channel":"WEB","config-deeplinkurl":`${_vm.opapWidgetAccountDomain}/my-account/`}}),_c('div',{attrs:{"id":"register","data-game-type":"casino","data-widget-host":"club","data-config-channel":"WEB"}}),_c('div',{attrs:{"id":"termsAndConditions","data-game-type":"casino","data-widget-host":"club","data-config-channel":"WEB"}}),_c('div',{attrs:{"id":"kyc","data-game-type":"casino","data-widget-host":"club","data-config-channel":"WEB"}}),_c('div',{attrs:{"id":"emailVerification","data-game-type":"casino","data-widget-host":"club","data-config-channel":"WEB"}}),(_vm.frameSrc)?_c('iframe',{attrs:{"id":"ssoFrame","hidden":"hidden","src":_vm.frameSrc,"title":"ssoFrame"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }