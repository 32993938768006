
import campaignService from '@/services/campaign-service'
export default {
    state: {
        availableCampaign: null
    },
    mutations: {
        setAvailableCampaign(state, campaign) {
            state.availableCampaign = campaign
        }
    },
    actions: {
        async refreshAvailableCampaign({ commit }) {
            const campaign = await campaignService.getAvailable()
            commit('setAvailableCampaign', campaign)
        }
    }
}
