<template>
    <div
        v-if="availableLanguages && availableLanguages.length > 1"
        class="lang-preference"
    >
        <select
            v-model="localLanguage"
            class="language-select"
            @change="updateLanguage"
        >
            <option
                v-for="availableLanguage in availableLanguages"
                :key="availableLanguage.value"
                :value="availableLanguage.value"
            >
                {{ availableLanguage.navbarTitle }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                localLanguage: null
            }
        },
        computed: {
            selectedLanguage() {
                return this.$store.getters.getLanguage
            },
            availableLanguages() {
                return this.$store.getters.getAvailableLanguages
            }
        },
        created() {
            this.localLanguage = this.selectedLanguage
        },
        methods: {
            updateLanguage() {
                this.$store.commit('setLanguage', this.localLanguage)
            }
        }
    }
</script>

<style scoped>
.lang-preference {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--ps-black);
    width: 150%;
    position: relative;
}

.lang-preference::before {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    background: url('~@/assets/images/chevron-down.png') no-repeat center center;
    position: absolute;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.language-select {
    appearance: none;
    margin: 0;
    width: 100%;
    outline: none;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--ps-black);
    background: transparent;
    border-radius: 0.25em;
    padding: 0.25em 1em;
    font-size: 1rem;
    cursor: pointer;
    line-height: 1.5;
}

.language-select::-ms-expand {
    display: none;
}

@media screen and (min-width: 992px) {
    .lang-preference {
        width: 100%;
    }
}
</style>
