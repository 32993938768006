export default {
    state: {
        language: sessionStorage.getItem('selectedLanguage') || 'el',
        availableLanguages: [
            {
                navbarTitle: 'Ελληνικά',
                footerTitle: 'ΕΛ',
                value: 'el'
            },
            {
                navbarTitle: 'English',
                footerTitle: 'EN',
                value: 'en'
            }
        ],
        theming: null,
        isSpringboardOpen: false
    },
    mutations: {
        setTheming: (state, theming) => {
            state.theming = theming
        },
        setLanguage: (state, language) => {
            state.language = language
            sessionStorage.setItem('selectedLanguage', language)
            location.reload()
        },
        setAvailableLanguages: (state, languages) => {
            state.availableLanguages = languages
        },
        setIsSpringboardOpen: (state, value) => {
            state.isSpringboardOpen = value
        }
    }
}
