import jwtUtils from '@/utils/jwt-utils'
const getters = {
    isLoggedIn: state => state.auth.jwtToken && jwtUtils.isValid(state.auth.jwtToken),
    getLanguage: state => state.app.language,
    getAvailableLanguages: state => state.app.availableLanguages,
    getDailyParticipation: state => state.dailyModal.dailyParticipation,
    getIsModalOpen: state => state.dailyModal.isModalOpen,
    getIsSpringboardOpen: state => state.app.isSpringboardOpen,
    getJwtToken: state => state.auth.jwtToken,
    getFreeGames: state => state.campaign?.availableCampaign?.freeGames,
    getCampaignHistory: state => state.campaign.campaignHistory,
    getPromoCategories: state => state.campaign.promoCategories,
    getLoggedInPlayerUsername: state => state.auth.loggedInPlayerUsername,
    getCurrentSession: state => state.tiers.currentSession,
    getTierList: state => state.tiers.tierList,
    getCurrentTier: state => state.tiers.currentTier,
    getTheming: state => state.app.theming,
    getTotalReward: state => state.campaign?.availableCampaign?.reward
}

export default getters
