import restAPI from '@/utils/rest-api'
export default {
    login(code) {
        return restAPI.post('/session/login', {
            code
        })
    },
    getCurrentSession() {
        return restAPI({
            url: '/session/current',
            method: 'get'
        })
    }
}
